import React, { useState, useRef, useEffect } from "react"
import { window, document } from "browser-monads"
import useBreakpoint from "~utils/useBreakpoint"
import { useSetNavStatus } from "~context/siteContext"
import Button from "~components/global/button"
import smoothscroll from 'smoothscroll-polyfill';

const FullWidthButton = ({ buttonFunction, text, color, sectionRef }) => {
  const { mobile } = useBreakpoint()
  const updateNavStatus = useSetNavStatus()
  const openShopNav = e => {
    e.target.blur()
    if(window.innerWidth > 1024){
      updateNavStatus('shop-open')
    }
    else{
      updateNavStatus('mobile-shop-open')
    }
  }

  const scrollDown = e => {
    e.preventDefault()
    let offset = sectionRef.current.getBoundingClientRect().top + sectionRef.current.clientHeight
    offset = offset - (mobile ? 50 : 80)
    window.scrollBy({top: offset, behavior: 'smooth'})
  }

  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  if(!buttonFunction){
    return null
  }

  switch (buttonFunction) {
    case "open-menu":
      return(
        <Button onClick={e => openShopNav(e)} color={color}>
          {text}
        </Button>
      )
    case "scroll-down":
      return(
        <Button className="scroll-down" onClick={e => scrollDown(e)} color={color}>
          {text}
        </Button>
      )
    default:
      return null
  }
}

const FullWidth = ({ desktopSrc, mobileSrc, children, media, image, mobileImage, buttonFunction, buttonText, buttonColor, textMode }) => {
  const { mobile, tablet } = useBreakpoint()
  const src = tablet ? mobileSrc : desktopSrc
  const sectionRef = useRef()
  const [isVideoLoaded, setIsVideoLoaded] = useState(true)
  return(
    <section className={`full-width full-width--${buttonFunction} full-width--${textMode}`} ref={sectionRef}>
      {media === "video" &&
        <video playsInline autoPlay muted loop onPlay={() => console.log('playing')}>
        {
          // need to come back and fix this loading
        }
          <source src={src} />
        </video>
      }
      {media === "image" &&
      <div className="full-width__image">
        {mobile && mobileImage}
        {!mobile && image}
      </div>
      }
      <div className="container">
        <div className="full-width__text">
          {children}
          <FullWidthButton buttonFunction={buttonFunction} color={buttonColor} text={buttonText} sectionRef={sectionRef}/>
        </div>
      </div>
    </section>
  )
}

export default FullWidth
