import React from "react"

const ProductDetail = ({ colors, media, children, ref }) => {
  return(
    <section className="product-detail" ref={ref}>
      <div className="product-detail__container">
        <div className="product-detail__col product-detail__media" style={{background: colors.base[1]}}>
          {media}
        </div>
        <div className="product-detail__col product-detail__text" style={{background: colors.safe[0]}}>
          <div className="product-detail__text-content" style={{color: colors.safe[1]}}>
            {children}
          </div>
        </div>
        <div className="product-detail__text-bg" style={{background: colors.safe[0]}} />
      </div>
    </section>
  )
}

export default ProductDetail
