import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { window } from "browser-monads"
import useBreakpoint from "~utils/useBreakpoint"
import resolveLink from "~utils/resolveLink"
import BlockContent from '@sanity/block-content-to-react'
import useCheckoutSettings from "~utils/useCheckoutSettings"

import Section from "~components/global/section"
import Stars from "~components/global/stars"
import ReviewsGrid, { ReviewsStampedHome } from "~components/modules/reviewsGrid"
import Slider from "~components/global/slider"
import Newsletter from '~components/global/newsletter'
import Video from "~components/video"
import Accordion, { AccordionSection, ProfileGridRow } from "~components/global/accordion"
import { SelectButton, SubmitButton } from "~components/global/formElements"
import QAndA from "~components/global/qAndA"

import { SanityImage } from "~components/sanityImage"
import Button from "~components/global/button"
import FullWidth from "~components/modules/fullWidth"
import Ticker from "~components/modules/ticker"
import TextBlock from "~components/modules/textBlock"
import IlloGrid from "~components/modules/illoGrid"
import GroupGrid, { GroupTile } from "~components/modules/groupGrid"
import TextImage from "~components/modules/textImage"
import TextIllo from "~components/modules/textIllo"
import ProductSlider from "~components/modules/productSlider"
import Divider from "~components/modules/divider"
import Tile from "~components/modules/tile"
import ProductDetail from "~components/modules/productDetail"
import ReviewsGridAlt, { ReviewTileAlt, ReviewsStamped } from "~components/modules/reviewsGridAlt"
import FeaturedReviews from "~components/modules/featuredReviews"
import ContactForm from "~components/global/contactForm"
import VideoModule from "./modules/videoModule"

const RenderModule = ({ m, type, productColors, scrollToAdd, productTitle }) => {
  const { mobile, tablet } = useBreakpoint()
  const { cartTitle } = useCheckoutSettings()

  const richTextSerializers = {
    types: {
      basicImage: ({ node }) => (
        <figure>
          <SanityImage imageId={node.asset && node.asset._ref} alt={node.alt}/>
          {node.caption &&
            <figcaption>{node.caption}</figcaption>
          }
        </figure>
      ),
      richTextButton: ({ node }) => (
        <>
        <a href={node.href} className="pill pill--solid-orange" target={node.target ? '_blank' : '_self'}>{node.text} <span className="pill__arrow">&rarr;</span></a><br/>
        </>
      )
    }
  }

  const textImageSerializers = {
    types: {
      internalButton: props => (
        <Button color={props.node.color} to={resolveLink(props.node.to)}>
          <>
          {props.node.buttonText}
          </>
        </Button>
      ),
      externalButton: props => (
        <Button href={props.node?.url} color="orange">
          {props.node?.buttonText}
        </Button>
      ),
      iconRow: props => {
      return(
        <>
        {!tablet &&
          <div className="text-image__steps">
            {props.node.icons.map(i => (
              <div>
                <SanityImage imageId={i.image.asset.id} />
                <h3 className="s-caps">{i.title}</h3>
                <p>
                  {i.text}
                </p>
              </div>
            ))}
          </div>
        }
        {tablet &&
          <Slider swipeIndicator={true}>
            {props.node.icons.map(i => (
              <div className="illo-slider__slide">
                <div className="illo-slider__image">
                  <SanityImage imageId={i.image.asset.id} />
                </div>
                <h5 className="s-caps">{i.title}</h5>
                <h3 className="m-serif">{i.text}</h3>
              </div>
            ))}
          </Slider>
        }
        </>
      )}
    }
  }

  const highlight = props => {
    return (
      <span>
        {props.children}
      </span>
    )
  }

  const BlockRenderer = props => {
    const {style = 'normal'} = props.node;

    // if (/^h\d/.test(style)) {
    //   const level = style.replace(/[^\d]/g, '')
    //   return React.createElement(style, { className: `heading-${level}`}, props.children)
    // }

    // if (style === 'blockquote') {
    //   return <blockquote>- {props.children}</blockquote>
    // }

    if (style === 'normal') {
      return <h3 className="l-sans">{props.children}</h3>
    }

    // Fall back to default handling
    return BlockContent.defaultSerializers.types.block(props)
  }

  switch (type) {
    case "fullWidth":
      return(
        <FullWidth media="image"
        desktopSrc={m.bgVideoLandscape ? m.bgVideoLandscape.asset?.url : null}
        mobileSrc={m.bgVideoPortrait ? m.bgVideoPortrait.asset.url : null}
        image={<Img fluid={m.bgImage && m.bgImage.asset.fluid ? {... m.bgImage.asset.fluid, sizes: '(max-width: 1600px) 100vw, 1600px'}: null} alt={m.title}/>}
        mobileImage={<Img fluid={m.bgImagePortrait && m.bgImagePortrait.asset.fluid} alt={m.title}/>}
        buttonFunction={m.buttonType}
        buttonColor={m.buttonColor}
        textMode={m.textMode}
        buttonText={m.buttonText}>
          <h1 className="l-sans">{m.title}</h1>
          <h3 className="m-serif">{m.subtitle}</h3>
        </FullWidth>
      )
    case "ticker":
      return(
        <Ticker text={m._rawText && m._rawText[0].children.map(c => c.text).join(' ')} bg={m.background && m.background.hex} textColor={m.textColor && m.textColor.hex} link={m._rawLink ? resolveLink(m._rawLink) : null}>
          {m._rawText && m._rawText[0].children.map(c => (
            <span style={c.marks.includes('highlight') && m.highlightColor ? {color: m.highlightColor.hex} : null}>{c.text}</span>
          ))}
        </Ticker>
      )
    case "richText":
      return(
        <Section name="rich-text">
          <div className="rich-text__el">
            <BlockContent blocks={m._rawBody} serializers={richTextSerializers} />
          </div>
        </Section>
      )
    case "centeredText":
      return(
        <TextBlock image={<Img fluid={m.image ? m.image.asset?.fluid : null} alt={m.title}/>}>
          {m.title &&
            <h4>{m.title}</h4>
          }
          <BlockContent blocks={m._rawText} />
        </TextBlock>
      )
    case "iconRow":
      return(
        <>
        {!mobile && <IlloGrid style={{background: '#FFFFFF'}} blocks={m.icons} />}
        {mobile &&
          <Section name="illo-slider">
            <Slider swipeIndicator={true}>
              {m.icons.map(i => (
                <div className="illo-slider__slide">
                  <div className="illo-slider__image">
                  {i.image &&
                    <Img fluid={i.image.asset.fluid} alt={i.title}/>
                  }
                  </div>
                  <h3 className="m-serif">{i.title}</h3>
                  <h4 className="xs-serif">{i.text}</h4>
                </div>
              ))}
            </Slider>
          </Section>
        }
        </>
      )
    case "groupGrid":
      return(
        <GroupGrid title={m.title} bg={m.bg}>
          {
            // min 3, max 4 (4th only displays on larger screens, or mobile)
            m.groups.map(g => (
              <GroupTile
              title={g.title}
              description={g.subtitle}
              to={resolveLink(g.group)}
              buttonText={g.buttonText}
              key={g.group && g.group.slug.current}
              fluid={g.image && g.image.asset.fluid}/>
            ))
          }
        </GroupGrid>
      )
    case "textImage":
      return(
        <TextImage 
        textAlign={m.textAlign} 
        textBg={m.textBackground} 
        flip={m.order} 
        imageFit={m.imageFit} 
        videoSrc={m.video?.asset?.url} 
        imageStyle={m.imageBackground && {background: m.imageBackground.hex}}
        img={
          m.image?.asset?._id?.includes('gif') ? 
          <img src={m.image?.asset?.url}/> : 
          <Img
          fluid={m.image && m.imageFit === 'contain' ? m.image && { ...m.image?.asset?.fluid, base64: null }: m.image && m.image.asset.fluid}
          imgStyle={{
            objectFit: m.imageFit
          }}
        />}>
          <BlockContent blocks={m._rawText} serializers={textImageSerializers}/>
        </TextImage>
      )
    case "contactForm":
      return(
        <ContactForm text={m._rawText} formName={m.formName} />
      )
    case "reviewsGrid":
      return(
        <>
          <ReviewsGrid content={m}/>
          <ReviewsStampedHome />
        </>
      )
    case "productSlider":
      return(
        <ProductSlider content={m} />
      )
    case "textIllo":
      return(
        <TextIllo cta={m._rawCta && m._rawCta[0]} bg={m.backgroundType} image={<Img fluid={m.illo && m.illo.asset.fluid} />}>
          <BlockContent blocks={m._rawHeading} serializers={{types: {block: BlockRenderer}, marks: {highlight}}} />
        </TextIllo>
      )
    case "headingText":
      return(
        <Section name={`heading-text padded ${m.center ? `centered` : null}`}>
          <h3 className={`l-sans ${m._rawText ? `margin` : null}`}>
            {m.title}
            </h3>
          <div>
            <BlockContent blocks={m._rawText} />
          </div>
        </Section>
      )
    case "divider":
      return(
        <Divider width={m.width}/>
      )
    case "tile":
      return(
        <Tile 
        title={m.title}
        image={
          m.image?.asset?._id?.includes('gif') ? 
          <img src={m.image.asset?.url}/> : 
          <Img fluid={m.image ? m.image.asset?.fluid : null}
          />       
        } flip={m.order} ctas={m.cta}>
          {m.title &&
            <h4 className="s-caps">
              The short
            </h4>
          }
          <h3 className="l-sans">
            {m.title}
          </h3>
          {m._rawText &&
            <h4 className="s-caps">
              The bit more
            </h4>
          }
          <div>
            <BlockContent blocks={m._rawText} />
          </div>
          {m.cta && m.cta.length > 0 &&
            <h4 className="s-caps">
              The more more
            </h4>
          }
        </Tile>
      )
    case "faqs":
      return(
        <Section name="faq padded">
          <h3 className="l-sans">{m.title}</h3>
          <div className="faq__questions">
            {m.questions && m.questions.map(q => (
              <QAndA question={q.question} key={q._key}>
                <BlockContent blocks={q._rawAnswer} />
              </QAndA>
            ))}
          </div>
        </Section>
      )
    case "productDetail":
      return(
        <ProductDetail colors={productColors}
          media={
            <>
            {m.media && m.media.map(item => (
              <>
                {item._type === 'imageSlider' &&
                  <div className="basic-slider" style={{color: productColors.safe[0]}}>
                    <Slider arrows="serif" nums={true}>
                      {item.images && item.images.map(image => (
                        <div className="basic-slider__slide">
                          <Img fluid={image.asset && image.asset.fluid} />
                        </div>
                      ))}
                    </Slider>
                  </div>
                }
                {item._type === 'image' &&
                <div className="product-detail__media-item">
                  <Img fluid={item.asset && item.asset.fluid} />
                </div>
                }
                {item._type === 'file' &&
                <div className="product-detail__media-item">
                  <Video src={item.asset && item.asset.url} />
                </div>
                }
              </>
            ))}
            </>
          }
        >
          <h3 className="m-caps" style={{color: productColors.highlight ?? '#ffffff'}}>{productTitle}</h3>
          <div>
            <BlockContent blocks={m._rawText} />
          </div>
          {m._rawAccordion &&
            <Accordion highlight={productColors.highlight}>
              {m._rawAccordion && m._rawAccordion.map(section => (
                <AccordionSection title={section.title} key={section._key}>
                  {section._type === "accordionText" &&
                    <p>
                      {section.text}
                    </p>
                  }
                  {section._type === "accordionRatingGrid" &&
                  <div className="profile-grid">
                    <ProfileGridRow type="header" title={section.subtitle}/>
                    {section.row && section.row.map(r => (
                      <ProfileGridRow title={r.title} rating={r.rating ?? 0}/>
                    ))}
                  </div>
                  }
                </AccordionSection>
              ))}
            </Accordion>
          }
          <SubmitButton onClick={e => scrollToAdd(e)} bg={productColors.safe[1]} textColor={productColors.safe[0]}>
            Add to {cartTitle}
          </SubmitButton>
        </ProductDetail>
      )
    case "productReviews":
      return(
        <>
        <FeaturedReviews reviewCount={1137} rating={5}>
        {m.featuredReviews && m.featuredReviews.map(review => (
          <div className="featured-reviews__slide" key={review._key}>
            <h3 className="l-serif">
              {review.review}
            </h3>
            <h4 className="s-caps">
            {review.name}<br/>
            {review.location}
            </h4>
          </div>
        ))}
        </FeaturedReviews>
        <ReviewsGridAlt>
          {m.reviews && m.reviews.map(review => (
            <ReviewTileAlt rating={parseInt(review.rating)} name={review.name} date={review.date}>
              <BlockContent blocks={review._rawReview} />
            </ReviewTileAlt>
          ))}
        </ReviewsGridAlt>
        <ReviewsStamped  stampedReviews={m.stampedReviews}/>
        </>
      )
    case "videoEmbed":
      return(
        <Section className="video-module">
            <div className="video-container">
              <VideoModule
                video={m.url} 
              />
            </div>
        </Section> 
      )
    default:
      return null
  }
}

export default RenderModule
