import React, { useState } from 'react'
import Section from '~components/global/section'
import { ArrowCircle } from "~components/global/svg"

const QAnda = ({question, children, key}) => {
  const [open, setOpen] = useState(false)
  return(
    <div className={`q-and-a ${open ? 'q-and-a--open' : ''}`}>
      <h4 className="m-serif" role="button" onClick={() => setOpen(!open)}><span>{question}</span> <ArrowCircle /></h4>
      <div className="q-and-a__answer">
        {children}
      </div>
    </div>
  )
}

export default QAnda
