import React, { Children } from 'react'
import Img from 'gatsby-image'
import Section from '~components/global/section'
import Button from '~components/global/button'
import { Link } from 'gatsby'
import useBreakpoint from '~utils/useBreakpoint'

export const GroupTile = ({ title, description, style, to, fluid, buttonText }) => {
  to = to || "/"
  return (
    <div className="group-tile" style={style}>
      <Link to={to}>
        <Img fluid={fluid} />
      </Link>
      <div className="group-tile__text">
        <h4 className="m-caps">
          {title}
        </h4>
        <h5 className="s-serif">
          {description}
        </h5>
        <Button to={to}>
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

const GroupGrid = ({ title, bg, children }) => {
  const { desktop, mobile } = useBreakpoint()
  const n = Children.count(children)
  return(
    <Section name={`group-grid group-grid--${n} group-grid--${bg}`}>
      <h2 className="l-sans">{title}</h2>
      {children}
    </Section>
  )
}

export default GroupGrid
