import React from 'react'
import Section from '~components/global/section'

const Divider = ({ width }) => (
  <Section name={`divider ${width}`}>
    <div/>
  </Section>
)

export default Divider
