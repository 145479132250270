import React from 'react'
import Img from 'gatsby-image'
import resolveLink from '~utils/resolveLink'

import Section from '~components/global/section'
import Slider from '~components/global/slider'
import Button from '~components/global/button'
import useBreakpoint from '~utils/useBreakpoint'

const ProductSlide = ({ content }) => (
  <div className="product-slide">
    <div className="product-slide__image">
      <Img fluid={content.image && content.image.asset.fluid} />
    </div>
    <div className="product-slide__text">
      <div className="product-slide__heading">
        <h3 className="m-caps">{content.title}</h3>
        <h4 className="m-serif">{content.subtitle}</h4>
      </div>
      <div className="product-slide__body">
        <p>
          {content.text}
        </p>
      </div>
      <Button to={resolveLink(content._rawLink)}>
        {content.buttonText}
      </Button>
    </div>
  </div>
)

const ProductSlider = ({ children, content }) => {
  const { lgdesktop, tablet, mobile } = useBreakpoint()
  if(!content){
    return null
  }
  return(
    <Section name="product-slider">
      <h3 className="l-sans">
        {content.title}
      </h3>
      <div className="product-slider__slider">
        <Slider slidesVisible={lgdesktop ? 1 : 2} arrows="sans" swipeIndicator={mobile}>
          {content.slides && content.slides.map(s => (
            <ProductSlide content={s} />
          ))}
        </Slider>
      </div>
    </Section>
  )
}

export default ProductSlider
