import React from 'react'
import Slider from '~components/global/slider'
import Section from '~components/global/section'
import Stars from '~components/global/stars'

const FeaturedReviews = ({ children, reviewCount, rating }) => {
  function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return(
    <Section name="featured-reviews">
      <div className="featured-reviews__slider">
        <div className="featured-reviews__summary">
          {rating &&
            <Stars num={rating}/>
          }
          {reviewCount &&
            <h5 className="m-caps">{numberWithCommas(reviewCount)} Reviews</h5>
          }
        </div>
        <Slider arrows="sans">
          {children}
        </Slider>
      </div>
    </Section>
  )
}

export default FeaturedReviews
