import React from 'react'
import Section from '~components/global/section'

const TextBlock = ({ children, image }) => (
  <Section name="text-block">
    <div className="text-block__el">
      <div className="text-block__image">
        {image}
      </div>
      {children}
    </div>
  </Section>
)

export default TextBlock
