import React from 'react'

const Stars = ({ num, remainder }) => {
  num = num || 5
  
  return (
    <div className="stars">
      {[...Array(num)].map(() =>
        <svg viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.64478 17.3333L5.91629 10.7044L0.111328 6.57979H7.27919L9.50022 0L11.7212 6.57979H18.8891L13.0337 10.7044L15.3557 17.3333L9.50022 13.2087L3.64478 17.3333Z" fill="currentColor"/>
        </svg>      
      )}
      {remainder && 
        [...Array(5 - num)].map(() =>
          <svg viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.91629 10.7044L3.64478 17.3333L9.50022 13.2087L15.3557 17.3333L13.0337 10.7044L18.8891 6.57979H11.7212L9.50022 0L7.27919 6.57979H0.111328L5.91629 10.7044ZM3.24519 7.57979L7.10444 10.3219L5.59072 14.7394L9.50022 11.9855L13.3802 14.7186L11.8401 10.322L15.733 7.57979H11.0034L9.50022 3.12672L7.99707 7.57979H3.24519Z" fill="currentColor"/>
          </svg>     
        )
      }
    </div>
  )
}

export default Stars
