import React from 'react'
import Stars from '~components/global/stars'
import { Facebook, Twitter, Email, Instagram } from '~components/global/svg'

const PlatformIcon = ({ platform }) => {
  switch (platform) {
    case 'facebook':
      return <Facebook />
    case 'twitter':
      return <Twitter />
    case 'email':
      return <Email />
    case 'instagram':
      return <Instagram />
    default:
      return false
  }
}

const ReviewTile = ({ image, name, username, platform, children, rating, link }) => (
  <div className="review-tile">
    <div className="review-tile__header">
      {image &&
        <div className="review-tile__image">
          {image}
        </div>
      }
      <div className="review-tile__meta">
        <h5 className="m-caps">{name}</h5>
        {username &&
          <a href={link}>{username}</a>
        }
        {rating && !username &&
          <Stars num={rating}/>
        }
      </div>
      {platform &&
      <div className="review-tile__platform">
        <PlatformIcon platform={platform} />
      </div>
      }
    </div>
    <div className="review-tile__body">
      {children}
      <a href="#" className="pill pill--orange" style={{display: 'none'}}>Read more</a>
    </div>
  </div>
)

export default ReviewTile
