import React from 'react'
import { Link } from 'gatsby'
import resolveLink from '~utils/resolveLink'
import Section from '~components/global/section'
import { paramCase } from 'change-case'

const TileCta = ({ cta }) => {
  switch (cta._type) {
    case 'externalLink':
    return(
      <>
      <a href={cta.url} target="_blank" className="pill pill--solid-green">{cta.linkText} <span className="pill__arrow">&rarr;</span></a>
      <br />
      </>
    )
    case 'internalLink':
    return(
      <>
      <Link to={resolveLink(cta._rawTo)} className="pill pill--solid-green">{cta.linkText} <span className="pill__arrow">&rarr;</span></Link>
      <br />
      </>
    )
    default:
    return null
  }
}

const Tile = ({children, image, flip, ctas, title}) => (
  <Section name={`tile padded ${flip ? 'tile--flip' : ''}`} id={title ? paramCase(title) : null}>
    <div className="tile__image">
      {image}
    </div>
    <div className="tile__text">
      {children}
      <div>
        {ctas && ctas.map(cta => <TileCta cta={cta} key={cta._key}/>)}
      </div>
    </div>
  </Section>
)

export default Tile
