import React, { useEffect, useState } from 'react'
import Stars from '~components/global/stars'
import Section from '~components/global/section'
import { Tick } from '~components/global/svg'
import moment from 'moment'
import Button from '../global/button'

export const ReviewTileAlt = ({children, rating, name, date}) => {
  return(
    <div className="review-tile-alt">
      <div className="review-tile-alt__review">
        {children}
      </div>
      <div className="review-tile-alt__meta">
        <Stars num={rating} />
        <h5>
          {name} <Tick />
        </h5>
        {date &&
          <h6 className="xs-serif">
            {moment(date).format('DD/MM/YY')}
          </h6>
        }
      </div>
    </div>
  )
}

export const ReviewsStamped = ({ children, stampedReviews}) => {
  
  const [reviews, setReviews] = useState([])
  const [reviewsTotal, setReviewsTotal] = useState(5)

  useEffect(()=> {
      setReviews(stampedReviews?.data?.slice(0, 5))
  }, [stampedReviews])

  const loadMore = () => {
    setReviews([...reviews, ...stampedReviews?.data?.slice(reviewsTotal, (reviewsTotal + 5))])
    setReviewsTotal(reviewsTotal + 5)
  }

  const numberWithCommas = (x) => {
    if(!x) return null
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return(
    <>
      {stampedReviews?.data?.length >= 1 && 
        <>
          <Section>
            <div className="review-stat-container">
              <h5>Overall Rating</h5>
              <div>
                <Stars num={stampedReviews?.ratingAll} remainder={true}/> 
                <h6 class="xs-serif">{stampedReviews?.rating} from {numberWithCommas(stampedReviews?.total)} reviews</h6>
              </div>
            </div>
          </Section>
          <Section className="reviews-section product">
            {reviews?.length &&
              reviews?.map(review => (
                <div className="review-line-item" key={review.id}>
                  <div className="review-item-column" >
                    <div>
                      <div className="stars-mobile" ><Stars num={review.reviewRating} remainder={true} /></div>
                      <div className="author"><h5 >{review.author}</h5> <Tick /></div>
                      <h6 className="xs-serif date">{moment(review.dateCreated).format('DD/MM/YY')}</h6>
                    </div>
                    <div className="verified">
                    <Tick /> <h6 class="xs-serif">Verified</h6> 
                    </div>
                    <p className="response">{review.reviewReply}</p>
                  </div>
                  <div className="review-item-column" >
                    <div className="inner">
                      <div className="stars-desktop" ><Stars num={review.reviewRating} remainder={true}/></div>
                      <h5>{review.reviewTitle}</h5>
                      <p className="message">{review.reviewMessage}</p>
                      <p className="response">{review.reviewReply}</p>
                    </div>
                  </div>
                </div>
              ))
          }
          { reviews?.length !== stampedReviews?.data?.length &&
            <div className="load-more-section">
                <Button onClick={()=> loadMore()} arrow={`down`}>load More</Button>
            </div>
          }
          </Section>
        </>
      }
    </>
  )
}

const ReviewsGridAlt = ({ children }) => (
  <>
  <Section name="reviews-grid-alt">
    {children}
  </Section>
  </>
)

export default ReviewsGridAlt
