import React, { useState } from 'react'
import Section from '~components/global/section'
import { window } from 'browser-monads'
import Img from 'gatsby-image'

const IlloBlock = ({ text, hoverText}) => {
  const [hovering, setHovering] = useState(false)
  const [mousePos, setMousePos] = useState([0, 0])

  const setPos = pos => {
    if(pos[0] < window.innerWidth / 2){
      return(
        {
          left: (pos[0] + 10) + 'px',
          top: (pos[1] + 10) + 'px',
        }
      )
    }
    else{
      return(
        {
          left: (pos[0] - 200) + 'px',
          top: (pos[1] + 10) + 'px',
        }
      )
    }
  }

  return(
    <div className={`illo-block ${hoverText ? 'illo-block--has-hover' : ''}`} onMouseMove={e => setMousePos([e.clientX, e.clientY])} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      <Img fluid={null} />
      <h3 className="s-serif">{text}</h3>
      {hovering && hoverText &&
        <div className="illo-block__hover xs-serif" style={setPos(mousePos)}>
          {hoverText}
        </div>
      }
    </div>
  )
}

const IlloBlockStatic = ({ title, text, image }) => {
  return(
    <div className='illo-block'>
      <div className="illo-block__image">
        {image}
      </div>
      <h3 className="s-caps">{title}</h3>
      <div className="xs-serif">
        {text}
      </div>
    </div>
  )
}

const IlloGrid = ({ style, blocks }) => {
  return(
    <Section name="illo-grid" style={style}>
      <div className="illo-grid__grid">
        {blocks &&
          blocks.map(i => (
            <IlloBlockStatic key={i.title} title={i.title} text={i.text} image={i.image ? <Img fluid={i.image.asset.fluid} /> : null}/>
          ))
        }
      </div>
    </Section>
  )
}

export default IlloGrid
