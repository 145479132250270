import React, { useEffect, useState } from 'react'
import Img from 'gatsby-image'
import BlockContent from '@sanity/block-content-to-react'

import Slider from "~components/global/slider"
import ReviewTile from "~components/global/reviewTile"
import Stars from "~components/global/stars"
import Section from "~components/global/section"
import useBreakpoint from "~utils/useBreakpoint"
import { Tick } from '~components/global/svg'
import moment from 'moment'
import Button from '../global/button'


export const ReviewsStampedHome = ({stampedReviews}) => {
  
  const [reviews, setReviews] = useState([])
  const [initialReviews, setInitialReviews] = useState([])
  const [reviewsTotal, setReviewsTotal] = useState(5)

  // Start  fetch
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };

  useEffect(()=> {
    fetch(`https://stamped.io/api/widget/reviews?email&isWithPhotos&minRating&take=20&page=1&dateFrom&dateTo&storeUrl=au.podandparcel.com&apiKey=pubkey-0oNOELF740Lm1eoZK0W3mU7hX1rS08&type`, requestOptions)
    .then(response => response.text())
    .then(result => {
      const stampedResults = JSON.parse(result)
      setReviews(stampedResults.data.slice(0, 5))
      setInitialReviews(stampedResults.data)
    })
    .catch(error => console.log('error', error));
  }, [])

  // End  fetch
  const loadMore = () => {
    setReviews([...reviews, ...initialReviews.slice(reviewsTotal, (reviewsTotal + 5))])
    setReviewsTotal(reviewsTotal + 5)
  }

  return(
    <>
      <Section className="reviews-section">
        {reviews?.length &&
          reviews?.map(review => (
            <div className="review-line-item home" key={review.id}>
              <div className="review-item-column" >
                <div>
                  <div className="stars-mobile" ><Stars num={review.reviewRating} remainder={true} /></div>
                  <div className="author"><h5 >{review.author}</h5> <Tick /></div>
                  <h6 className="xs-serif date">{moment(review.dateCreated).format('DD/MM/YY')}</h6>
                </div>
                <div className="verified">
                <Tick /> <h6 class="xs-serif">Verified</h6> 
                </div>
                <p className="response">{review.reviewReply}</p>
              </div>
              <div className="review-item-column" >
                <div className="inner">
                  <div className="stars-desktop" ><Stars num={review.reviewRating} remainder={true}/></div>
                  <h5>{review.reviewTitle}</h5>
                  <p className="message">{review.reviewMessage}</p>
                  <p className="response">{review.reviewReply}</p>
                </div>
              </div>
            </div>
          ))
      }
      { reviews?.length !== initialReviews?.length &&
        <div className="load-more-section">
          <Button onClick={()=> loadMore()} arrow={`down`}>load More</Button>
        </div>
      } 
      </Section>
    </>
  )
}

export const ReviewsGrid = ({ content }) => {
  const { desktop, tablet, mobile } = useBreakpoint()

  if(!content){
    return null
  }

  let perChunk = 2
  let inputArray = content.reviews

  let desktopSlides = inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index/perChunk)
    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }
    resultArray[chunkIndex].push(item)
    return resultArray
  }, [])

  const mobileReviews = content.reviews  ? content.reviews.slice(0, 3) : []

  return(
    <Section name="reviews-grid">
      <h3 className="l-sans reviews-grid__title">
        {content.title}
      </h3>
      <div className="reviews-grid__summary">
        <Stars num={5} />
        <h4 className="m-caps">{content.subtitle}</h4>
      </div>
      <div className="reviews-grid__grid">
        {!mobile &&
        <div className="reviews-slider">
          <Slider slidesVisible={desktop ? 2 : 3} arrows="sans">
          {desktopSlides.map(slide => (
            <div className="reviews-slider__slide" key={slide[0].username}>
              {slide.map(r => (
                <ReviewTile key={r.username} image={r.image ? <Img fluid={r.image.asset.fluid} /> : null} name={r.name} username={r.username} platform={r.platform} rating={r.rating ? parseInt(r.rating) : null}>
                  <BlockContent blocks={r._rawReview} />
                </ReviewTile>
              ))}
            </div>
          ))}
          </Slider>
        </div>
        }
        {mobile &&
          <div className="reviews-grid__mobile">
            {mobileReviews.map(r => (
              <ReviewTile image={r.image ? <Img fluid={r.image.asset.fluid} /> : null} name={r.name} username={r.username} platform={r.platform} rating={r.rating ? parseInt(r.rating) : null}>
                <BlockContent blocks={r._rawReview} />
              </ReviewTile>
            ))}
          </div>
        }
      </div>
      <div className="reviews-grid__more">
        <a href="#" className="pill pill--orange" style={{display: 'none'}}>See More Reviews</a>
      </div>
    </Section>
  )
}

export default ReviewsGrid
